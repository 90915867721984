import React from "react";
import { GenericError } from "../../components/GenericError/GenericError";
import { Layout } from "../../components/Layout";

// Key: 01100001 01100011 01110011 01110011 01110101 01110000 01101100 01100010 00101110 01101111 01110010 01100111 00101111 01100110 01101001 01101110 01100001 01101100 01110011 00101111 01100101 01111000 01100101 01100011 01110100 01100101 01100001 01101101 01101001 01110011 01110100 01101000 01100101 01100010 01100101 01110011 01110100 01110100 01100101 01100001 01101101
// How to decrypt: convert to decimal, then map to ASCII

const SecretExecTeamIsTheBestTeam: React.FC = () => {
  return (
    <Layout hasFooter={false}>
      <GenericError
        error_message={"Hackerist?"}
        sub_message={"...and to be justifiably worthy of the title..."}
      />
    </Layout>
  );
};

export default SecretExecTeamIsTheBestTeam;
